import { JsonPipe, NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, forwardRef } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { IOption } from '@app/core/http/http-response.model';
import { TranslocoDirective } from '@jsverse/transloco';
import { AutocompleteComponent } from '@shared/autocomplete/autocomplete.component';
import { ChipComponent } from '@shared/chip/chip.component';

@Component({
  selector: 'ess-destination-select',
  standalone: true,
  imports: [NgFor, AutocompleteComponent, ChipComponent, MatChipsModule, JsonPipe, TranslocoDirective],
  templateUrl: './destination-select.component.html',
  styleUrls: ['./destination-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DestinationSelectComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DestinationSelectComponent),
      multi: true,
    },
  ],
})
export class DestinationSelectComponent implements ControlValueAccessor, Validator {
  @Input({ required: true }) endpoint!: string;
  isDisabled = false;
  selectedDestinations: IOption[] = [];
  searchControl: FormControl = new FormControl();
  searchValue = '';

  onChangeCb?: (model: DestinationSelectModel) => void;
  onTouchedCb?: () => void;

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.hasValidator(Validators.required)) return null;
    if (this.searchValue || this.selectedDestinations.length > 0) return null;

    return { required: true };
  }

  writeValue(model: DestinationSelectModel): void {
    if (!model) return;
    if (model.search) this.searchControl.setValue(model.search);
  }

  registerOnChange(fn: (model: DestinationSelectModel) => void): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedCb = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onEventSelect(select: IOption) {
    if (this.selectedDestinations.includes(select)) return;
    this.selectedDestinations.push(select);
    this.emitChanges();
  }

  trackByValue(index: number, value: IOption): string {
    return value.value;
  }

  onChipRemove(option: IOption): void {
    this.selectedDestinations.splice(this.selectedDestinations.indexOf(option), 1);
    this.emitChanges();
  }

  emitChanges(value: string | null = null) {
    if (value !== null) this.searchValue = value;
    this.onChangeCb?.({
      destinations: this.selectedDestinations.map(destination => parseInt(destination.value)),
      search: this.searchValue,
    });
  }
}

export interface DestinationSelectModel {
  destinations: number[];
  search: string;
}
