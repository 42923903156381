import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IOption } from '@app/core/http/http-response.model';
import { environment } from '@environment';
import { Observable, map } from 'rxjs';
import { HttpListResponse } from '@ess-front/shared';

@Injectable({
  providedIn: 'root',
})
export class CategoriesChoicesService {
  private endpointURL = `${environment.apiEnv}api/autocomplete/classification/category/?type=experience&exclude__id=20`;

  constructor(private httpClient: HttpClient) {}

  getCategoriesChoices$(): Observable<IOption[]> {
    return this.httpClient
      .get<HttpListResponse<IOption>>(this.endpointURL)
      .pipe(map(response => this.transformIntoFEIOption(response)));
  }

  private transformIntoFEIOption(response: HttpListResponse<IOption>): IOption[] {
    return response.results.map(result => ({
      label: result.label,
      value: result.value.toString(),
    }));
  }
}
