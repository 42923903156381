<ng-container *transloco="let t; prefix: 'requestTrip'">
  <ess-autocomplete
    formControlName="searchControl"
    [placeholder]="t('search')"
    appearance="outline"
    [emitSelection]="true"
    [endpoint]="endpoint"
    [selectSearch]="true"
    (eventSelect)="onEventSelect($event)"
    (searchChange)="emitChanges($event)"
    (blurred)="onTouchedCb?.()"
  />
  <div class="chip-selector-container" aria-label="{{ t('chipSelector') }}">
    @for (destination of selectedDestinations; track trackByValue($index, destination)) {
      <ess-chip
        [disabled]="isDisabled"
        [selected]="true"
        [value]="destination.value"
        (chipRemove)="onChipRemove(destination)"
        data-cy="destination-selected"
      >
        {{ destination.label }}
      </ess-chip>
    }
  </div>
</ng-container>
