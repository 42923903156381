<ng-container *transloco="let t; prefix: 'requestTrip'">
  <button
    class="button-content toggle-bg"
    type="button"
    [ngClass]="{'toggle-on': selected, disabled: disabled}"
    [@toggleBackground]="selected ? 'on' : 'off'"
    (click)="toggleClick()"
    data-cy="switch-button"
  >
    @if (showText) {
      <div class="bg-text">{{ selected ? t('no') : t('yes') }}</div>
    }
    <div [@toggleTrigger]="selected ? 'on' : 'off'" class="toggle" data-cy="switch-button-selected">
      @if (showText) {
        <div class="toggle-text">
          {{ selected ? t('yes') : t('no') }}
        </div>
      }
    </div>
  </button>
</ng-container>
