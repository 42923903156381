<ng-container *transloco="let t; prefix: 'requestTrip'">
  <ess-switch [(ngModel)]="specialRequestCheck" (click)="resetSpecialRequests()" />
  @if (specialRequestCheck) {
    <div class="special-request-container" data-cy="special-requests-container">
      <textarea
        class="sans"
        placeholder="{{ t('specialRequestPlaceholder') }}"
        aria-label="{{ t('specialRequestPlaceholder') }}"
        [(ngModel)]="specialRequests"
        (change)="onChange()"
        (blur)="onTouchedCb?.()"
        cdkTextareaAutosize
        cdkAutosizeMinRows="2"
        cdkAutosizeMaxRows="15"
        data-cy="special-requests-textarea"
      >
      </textarea>
    </div>
  }
</ng-container>
