import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateRange } from '@angular/material/datepicker';
import { ChipSelectorComponent } from '@shared/forms/controls/chip-selector/chip-selector.component';
import { CalendarComponent } from '@shared/forms/controls/calendar/calendar.component';
import { ControlGroupComponent } from '@shared/forms/controls/control-group/control-group.component';
import { SwitchComponent } from '@shared/forms/controls/switch/switch.component';
import { InputNumberComponent } from '@shared/forms/controls/input-number/input-number.component';
import { DestinationSelectComponent } from '@shared/forms/controls/destination-select/destination-select.component';
import { TravelersControlComponent } from '@shared/forms/controls/travelers-control/travelers-control.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '@shared/button/button.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SpecialRequestsControlComponent } from '@shared/forms/controls/special-requests-control/special-requests-control.component';
import { FormConfig } from '@shared/models/request-trip/form-config.model';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { AutocompleteComponent } from '@shared/autocomplete/autocomplete.component';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'ess-request-trip',
  templateUrl: './request-trip.component.html',
  styleUrls: ['./request-trip.component.scss'],
  standalone: true,
  imports: [
    ChipSelectorComponent,
    ControlGroupComponent,
    SwitchComponent,
    ReactiveFormsModule,
    InputNumberComponent,
    TravelersControlComponent,
    CalendarComponent,
    DestinationSelectComponent,
    AutocompleteComponent,
    MatIconModule,
    ButtonComponent,
    MatDialogModule,
    SpecialRequestsControlComponent,
    CdkScrollable,
    TranslocoDirective,
  ],
})
export class RequestTripComponent {
  @Input({ required: true }) formConfig!: FormConfig;
  @Output() formSubmit = new EventEmitter();

  form: FormGroup;
  constructor(private fb: FormBuilder) {
    this.form = this.initForm();
  }

  get destinations() {
    return this.form.get('destinations');
  }
  get dates() {
    return this.form.get('dates');
  }
  get tripType() {
    return this.form.get('tripType');
  }
  get passionsAndInterest() {
    return this.form.get('passionsAndInterest');
  }
  get travelers() {
    return this.form.get('travelers');
  }

  initForm(): FormGroup {
    return this.fb.group({
      destinations: new FormControl({ search: '', destinations: [] }, Validators.required),
      dates: new FormControl(new DateRange<Date>(null, null), Validators.required),
      tripType: new FormControl('', Validators.required),
      passionsAndInterest: new FormControl([], Validators.required),
      travelers: new FormControl(
        {
          adults: 1,
          children: 0,
          babies: 0,
        },
        Validators.required,
      ),
      airport: new FormControl(null),
      specialRequests: new FormControl(''),
    });
  }

  submit() {
    if (!this.form.valid) {
      this.markControlsAsTouched();
      return;
    }
    this.formSubmit.emit(this.form.value);
  }

  private markControlsAsTouched() {
    Object.keys(this.form.controls).forEach(key => {
      this.form.get(key)?.markAsTouched();
    });
  }
}
