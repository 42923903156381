<div class="container" [ngClass]="{disabled: disabled}" data-cy="input-number">
  <p class="sans placeholder" data-cy="input-number-title">
    {{ placeholder }}
    @if (subtitle) {
      <small data-cy="input-number-subtitle">{{ subtitle }}</small>
    }
  </p>
  <div class="counter-container">
    <button
      mat-icon-button
      type="button"
      [disabled]="disabled || counter <= 0"
      (click)="counterSubstract()"
      data-cy="input-number-remove"
    >
      <mat-icon>remove</mat-icon>
    </button>
    <span class="heading-m counter" data-cy="input-number-counter">
      {{ counter }}
    </span>
    <button mat-icon-button type="button" [disabled]="disabled" (click)="counterAdd()" data-cy="input-number-add">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>
