import { Component, Inject, SecurityContext } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { SafePipe } from '@shared/pipes/safe/safe.pipe';

@Component({
  selector: 'ess-schedule-call',
  templateUrl: './schedule-call.component.html',
  styleUrls: ['./schedule-call.component.scss'],
  standalone: true,
  imports: [MatDialogModule, MatIconModule, SafePipe],
})
export class ScheduleCallComponent {
  readonly SecurityContext = SecurityContext;
  constructor(@Inject(MAT_DIALOG_DATA) public data: { calendarUrl: string }) {}
}
