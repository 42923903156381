import { NgClass } from '@angular/common';
import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'ess-input-number',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, NgClass],
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputNumberComponent),
      multi: true,
    },
  ],
})
export class InputNumberComponent implements ControlValueAccessor {
  @Input({ required: true }) placeholder!: string;
  @Input() subtitle = '';
  disabled = false;
  counter = 0;

  onChangeCb?: (counter: number) => void;
  onTouchedCb?: () => void;

  writeValue(counter: number): void {
    this.counter = counter;
  }
  registerOnChange(fn: (counter: number) => void): void {
    this.onChangeCb = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouchedCb = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  counterAdd() {
    if (this.disabled) return;

    this.counter++;
    this.emitChanges();
  }

  counterSubstract() {
    if (this.counter <= 0 || this.disabled) return;

    this.counter--;
    this.emitChanges();
  }

  emitChanges() {
    this.onChangeCb?.(this.counter);
    this.onTouchedCb?.();
  }
}
