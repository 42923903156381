import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'ess-chip',
  standalone: true,
  imports: [NgClass, MatIconModule],
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipComponent {
  @Input() disabled = false;
  @Input() selected = false;
  @Input() value?: string;
  @Output() chipRemove: EventEmitter<void> = new EventEmitter();
  @Output() chipSelect: EventEmitter<boolean> = new EventEmitter();

  toggleChipState() {
    if (this.disabled) return;
    this.selected = !this.selected;
    if (this.selected) {
      this.selectChip();
    } else {
      this.removeChip();
    }
  }

  selectChip() {
    this.selected = true;
    this.chipSelect.emit(true);
  }
  removeChip() {
    this.selected = false;
    this.chipRemove.emit();
  }
}
