import { ChangeDetectionStrategy, Component, Input, OnInit, forwardRef } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { DateRange, MatDatepickerModule } from '@angular/material/datepicker';
import { CalendarCustomHeaderComponent } from './calendar-custom-header/calendar-custom-header.component';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'ess-calendar',
  standalone: true,
  imports: [MatCardModule, MatDatepickerModule, MatNativeDateModule, CalendarCustomHeaderComponent],
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CalendarComponent),
      multi: true,
    },
  ],
})
export class CalendarComponent implements OnInit, ControlValueAccessor, Validator {
  customHeader = CalendarCustomHeaderComponent;
  @Input() range = true;
  isDisabled = false;
  selectedRangeValue: DateRange<Date> | undefined;
  selectedValue: Date | undefined;
  minDay: Date = new Date();
  onChangeCb?: (selectedValue: DateRange<Date> | Date) => void;
  onTouchedCb?: () => void;

  ngOnInit(): void {
    this.minDay.setDate(this.minDay.getDate() + 1);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.hasValidator(Validators.required)) return null;
    if (!this.range && this.selectedValue) return null;
    if (this.range && this.selectedRangeValue?.start && this.selectedRangeValue?.end) return null;

    return { required: true };
  }

  writeValue(value: DateRange<Date> | Date) {
    if (!this.range && value instanceof Date) this.selectedValue = value;
    if (this.range && value instanceof DateRange) this.selectedRangeValue = value;
  }
  registerOnChange(fn: (selectedRange: DateRange<Date> | Date) => void) {
    this.onChangeCb = fn;
  }
  registerOnTouched(fn: () => void) {
    this.onTouchedCb = fn;
  }
  setDisabledState?(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  selectedChange(selectedDate: Date) {
    if (this.isDisabled || this.checkIsDateIsBeforeToday(selectedDate)) return;
    this.range ? this.manageSelectRange(selectedDate) : this.manageSelectDate(selectedDate);
  }

  private manageSelectDate(selectedDate: Date) {
    this.selectedValue = selectedDate;
    this.onChangeCb?.(this.selectedValue);
  }

  private manageSelectRange(selectedDate: Date) {
    if (!this.selectedRangeValue?.start || this.selectedRangeValue?.end) {
      this.selectedRangeValue = new DateRange<Date>(selectedDate, null);
    } else {
      const start = this.selectedRangeValue.start;
      const end = selectedDate;
      if (end < start) {
        this.selectedRangeValue = new DateRange<Date>(end, start);
      } else {
        this.selectedRangeValue = new DateRange<Date>(start, end);
      }
    }
    this.onChangeCb?.(this.selectedRangeValue);
  }

  private checkIsDateIsBeforeToday(date: Date): boolean {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date <= today;
  }
}
