import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormConfig } from '@shared/models/request-trip/form-config.model';
import { RequestTripModalComponent } from '@shared/request-trip/request-trip-modal.component';

@Injectable({
  providedIn: 'root',
})
export class RequestTripService {
  constructor(private dialog: MatDialog) {}

  openRequestTripDialog(formConfig: FormConfig): MatDialogRef<RequestTripModalComponent> {
    return this.dialog.open(RequestTripModalComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100vh',
      width: '100vw',
      data: { formConfig },
      autoFocus: false,
    });
  }
}
