import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Component, DestroyRef, forwardRef } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { InputNumberComponent } from '../input-number/input-number.component';
import { TranslationDomainService } from '@app/domain/translation-domain.service';
import { marker } from '@jsverse/transloco-keys-manager/marker';

@Component({
  selector: 'ess-travelers-control',
  standalone: true,
  imports: [ReactiveFormsModule, InputNumberComponent],
  templateUrl: './travelers-control.component.html',
  styleUrls: ['./travelers-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TravelersControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TravelersControlComponent),
      multi: true,
    },
  ],
})
export class TravelersControlComponent implements ControlValueAccessor, Validator {
  travelersForm: FormGroup;
  controlNames: string[];

  protected controlTitles: Record<keyof TravelersForm, string> = {
    adults: '',
    children: '',
    babies: '',
  };
  protected controlSubtitles: Record<keyof TravelersForm, string> = {
    adults: '(12+)',
    children: '(2-11)',
    babies: '',
  };

  constructor(
    private fb: FormBuilder,
    private destroyRef: DestroyRef,
    private translationDomainService: TranslationDomainService,
  ) {
    this.travelersForm = this.initForm();
    this.controlNames = this.getControlNames();
    this.subscribeFormChanges();
    this.prepareTranslations();
  }
  onTouchedCb?: () => void;
  onChangeCb?: (model: TravelersForm) => void;

  initForm(): FormGroup {
    return this.fb.group<{ [K in keyof TravelersForm]: FormControl }>({
      adults: new FormControl(0, [Validators.required, Validators.min(1)]),
      children: new FormControl(0),
      babies: new FormControl(0),
    });
  }

  getControlNames(): string[] {
    return Object.keys(this.travelersForm.controls);
  }

  prepareTranslations() {
    this.controlTitles.adults = this.translationDomainService.translate(marker('requestTrip.adults'));
    this.controlTitles.children = this.translationDomainService.translate(marker('requestTrip.children'));
    this.controlTitles.babies = this.translationDomainService.translate(marker('requestTrip.babies'));
    this.controlSubtitles.babies = this.translationDomainService.translate(marker('requestTrip.babiesSubtitle'));
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.hasValidator(Validators.required)) return null;
    return this.travelersForm.valid ? null : { required: true };
  }

  subscribeFormChanges() {
    this.travelersForm.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      this.onChangeCb?.(value);
    });
  }

  writeValue(formValue: TravelersForm): void {
    if (formValue)
      this.travelersForm.setValue(
        {
          adults: formValue.adults,
          children: formValue.children,
          babies: formValue.babies,
        },
        { emitEvent: false },
      );
  }
  registerOnChange(fn: (formValue: TravelersForm) => void): void {
    this.onChangeCb = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouchedCb = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.travelersForm.disable() : this.travelersForm.enable();
  }
}

interface TravelersForm {
  adults: number;
  babies: number;
  children: number;
}
