import { Component, Input, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { trigger, state, style, transition, animate, animateChild, query } from '@angular/animations';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'ess-switch',
  standalone: true,
  imports: [CommonModule, TranslocoDirective],
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true,
    },
  ],
  animations: [
    trigger('toggleBackground', [
      state('off', style({ background: '#d5d5d5' })),
      state('on', style({ background: '#303030' })),
      transition('on <=> off', [animate('120ms ease-in-out'), query('@toggleTrigger', animateChild())]),
    ]),
    trigger('toggleTrigger', [
      state('off', style({ transform: 'translateX(0%)' })),
      state('on', style({ transform: 'translateX(106%)' })),
      transition('on <=> off', [animate('200ms ease-in-out')]),
    ]),
  ],
})
export class SwitchComponent implements ControlValueAccessor {
  disabled = false;
  selected = false;
  @Input() showText = true;

  onChangeCb?: (selected: boolean) => void;
  onTouchedCb?: () => void;

  writeValue(selected: boolean): void {
    this.selected = selected;
  }
  registerOnChange(fn: (selected: boolean) => void): void {
    this.onChangeCb = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouchedCb = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  toggleClick() {
    if (this.disabled) return;
    this.selected = !this.selected;
    this.emitChanges();
    this.onTouchedCb?.();
  }

  emitChanges() {
    this.onChangeCb?.(this.selected);
  }
}
