<div class="control-group" data-cy="control-group">
  <div class="control-group-labels">
    <p class="sans">
      <span [ngClass]="{error: controlHasError()}" data-cy="control-group-label">
        <ng-content select="[label]"></ng-content>
      </span>
      <small data-cy="control-group-subtitle">
        <ng-content select="[label-subtitle]"></ng-content>
      </small>
    </p>
  </div>
  <ng-content></ng-content>
</div>
