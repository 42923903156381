<ng-container *transloco="let t; prefix: 'requestTrip'">
  <div class="chip-selector-container" aria-label="{{ t('chipSelector') }}">
    @for (chip of options; track trackByValue($index, chip.value)) {
      <ess-chip
        [disabled]="isDisabled"
        [selected]="chip.selected"
        [value]="chip.value"
        (chipRemove)="onChipRemove(chip)"
        (chipSelect)="onChipSelect(chip)"
        data-cy="selector-option"
      >
        {{ chip.description }}
      </ess-chip>
    }
  </div>
</ng-container>
