<mat-card class="inline-calendar">
  <mat-calendar
    class="ess-calendar"
    [headerComponent]="customHeader"
    [selected]="range ? selectedRangeValue : selectedValue"
    [minDate]="minDay"
    (selectedChange)="selectedChange($event)"
    (blur)="onTouchedCb?.()"
    data-cy="dates-calendar"
  />
</mat-card>
