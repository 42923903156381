<fieldset [formGroup]="travelersForm">
  @for (controlName of controlNames; track controlName) {
    <div class="formField">
      <ess-input-number
        placeholder="{{ controlTitles[controlName] }}"
        subtitle="{{ controlSubtitles[controlName] }}"
        [formControlName]="controlName"
        data-cy="travelers-input"
      />
    </div>
  }
</fieldset>
