import { Component, forwardRef } from '@angular/core';

import { SwitchComponent } from '../switch/switch.component';
import {
  AbstractControl,
  ControlValueAccessor,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { TextFieldModule } from '@angular/cdk/text-field';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'ess-special-requests-control',
  standalone: true,
  imports: [SwitchComponent, FormsModule, TextFieldModule, TranslocoDirective],
  templateUrl: './special-requests-control.component.html',
  styleUrls: ['./special-requests-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SpecialRequestsControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SpecialRequestsControlComponent),
      multi: true,
    },
  ],
})
export class SpecialRequestsControlComponent implements ControlValueAccessor, Validator {
  specialRequestCheck = false;
  specialRequests = '';
  isDisabled = false;
  onChangeCb?: (specialRequests: string) => void;
  onTouchedCb?: () => void;

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.hasValidator(Validators.required)) return null;
    if (this.specialRequests) return null;

    return { specialRequestsRequired: true };
  }

  writeValue(value: string): void {
    this.specialRequests = value;
  }
  registerOnChange(fn: (specialRequests: string) => void): void {
    this.onChangeCb = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouchedCb = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onChange() {
    this.onChangeCb?.(this.specialRequests);
  }
  resetSpecialRequests() {
    this.specialRequests = '';
    this.onChange();
  }
}
