import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'ess-control-group',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './control-group.component.html',
  styleUrls: ['./control-group.component.scss'],
})
export class ControlGroupComponent {
  @Input() control: AbstractControl | null = null;

  controlHasError() {
    return (this.control?.touched || this.control?.dirty) && !this.control?.valid;
  }
}
