<ng-container *transloco="let t; prefix: 'requestTrip'">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <ess-control-group [control]="destinations" data-cy="trip-modal-destinations">
      <ng-container label>{{ t('destination') }}</ng-container>
      <ng-container label-subtitle>{{ t('whereWouldYouLikeToGo') }}</ng-container>
      <ess-destination-select
        formControlName="destinations"
        [endpoint]="formConfig.destinatiosAutocompleteEndpoint"
        data-cy="destination-label"
      />
    </ess-control-group>
    <ess-control-group [control]="dates" data-cy="trip-modal-dates">
      <ng-container label>{{ t('dates') }}</ng-container>
      <ng-container label-subtitle>{{ t('whenAreYouPlanningToTravel') }}</ng-container>
      <ess-calendar formControlName="dates" />
    </ess-control-group>

    <ess-control-group [control]="tripType" data-cy="trip-modal-type">
      <ng-container label>{{ t('tripType') }}</ng-container>
      <ng-container label-subtitle>{{ t('whoAreYouPlanningToGoWith') }}</ng-container>
      <ess-chip-selector
        formControlName="tripType"
        [multiple]="false"
        [options]="formConfig.tripTypeOptions"
        data-cy="type-options"
      />
    </ess-control-group>

    <ess-control-group [control]="passionsAndInterest" data-cy="trip-modal-interests">
      <ng-container label>{{ t('passionsAndInterest') }}</ng-container>
      <ng-container label-subtitle>
        {{ t('passionAndInterestDescription') }}
      </ng-container>
      <ess-chip-selector formControlName="passionsAndInterest" [options]="formConfig.passionAndInterestOptions" />
    </ess-control-group>

    <ess-control-group [control]="travelers" data-cy="trip-modal-travelers">
      <ng-container label>{{ t('travelers') }}</ng-container>
      <ng-container label-subtitle>{{ t('howManyPeopleWillBeTravelingWithYou') }}</ng-container>
      <ess-travelers-control formControlName="travelers" />
    </ess-control-group>

    <ess-control-group data-cy="trip-modal-departure">
      <ng-container label>{{ t('whereAreYouDepartingFrom') }}</ng-container>
      <ng-container label-subtitle>{{ t('chooseAnAirport') }}</ng-container>
      <ess-autocomplete
        formControlName="airport"
        placeholder=" {{ t('select') }}"
        appearance="outline"
        [endpoint]="formConfig.airportAutocompleteEndpoint"
        data-cy="departure-label"
      />
    </ess-control-group>

    <ess-control-group data-cy="trip-modal-special-request">
      <ng-container label>{{ t('specialRequest') }}</ng-container>
      <ng-container label-subtitle>
        {{ t('specialRequestDescription') }}
      </ng-container>
      <ess-special-requests-control formControlName="specialRequests" data-cy="special-request-toggle-switch" />
    </ess-control-group>
    <ess-button class="full-width" buttonType="submit" data-cy="trip-modal-sendTrip-btn">{{
      t('startANewTrip')
    }}</ess-button>
  </form>
</ng-container>
