import { Component, Input, forwardRef } from '@angular/core';

import { ChipComponent } from '@shared/chip/chip.component';
import { ChipOption } from './chip-option.interface';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'ess-chip-selector',
  standalone: true,
  imports: [ChipComponent, MatChipsModule, TranslocoDirective],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipSelectorComponent),
      multi: true,
    },
  ],
  templateUrl: './chip-selector.component.html',
  styleUrls: ['./chip-selector.component.scss'],
})
export class ChipSelectorComponent implements ControlValueAccessor {
  selected: string | string[] = [];
  isDisabled = false;

  @Input({ required: true }) options!: ChipOption[];
  @Input() disabled = false;
  @Input() multiple = true;

  onChangeCb?: (selected: string | string[]) => void;
  onTouchedCb?: () => void;

  // #region ControlValueAccessor implementation
  writeValue(selectedOptions: string | string[]): void {
    this.resetOptionsSelected();

    if (this.multiple) {
      this.manageWriteMultiple(selectedOptions);
    } else {
      this.manageWriteSingle(selectedOptions);
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  registerOnChange(fn: (selected: string | string[]) => void): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedCb = fn;
  }
  // #endregion

  onChipRemove(chip: ChipOption) {
    if (this.multiple) {
      this.manageRemoveMultiple(chip);
    } else {
      this.manageRemoveSingle();
    }
    this.emitChanges();
  }

  onChipSelect(chip: ChipOption) {
    if (this.multiple) {
      this.manageSelectMultiple(chip);
    } else {
      this.manageSelectSingle(chip);
    }
    this.emitChanges();
  }

  trackByValue(index: number, value: string): string {
    return value;
  }

  private emitChanges() {
    this.onChangeCb?.(this.selected);
    this.onTouchedCb?.();
  }

  private manageRemoveMultiple(chip: ChipOption) {
    if (this.selected instanceof Array) {
      this.selected.splice(this.selected.indexOf(chip.value), 1);
    } else {
      this.resetOptionsSelected();
    }
  }

  private manageRemoveSingle() {
    this.resetOptionsSelected(true);
  }

  private manageSelectMultiple(chip: ChipOption) {
    if (this.selected instanceof Array) {
      this.selected.push(chip.value);
      return;
    }
    this.resetOptionsSelected();
  }

  private manageSelectSingle(chip: ChipOption) {
    this.resetOptionsSelected(true);
    chip.selected = true;
    this.selected = chip.value;
  }

  // to ensure any option that's not include on input options is selected find for the selected ones insided the component
  private manageWriteMultiple(selectedOptions: string | string[]) {
    const selected = selectedOptions instanceof Array ? selectedOptions : new Array(selectedOptions);
    this.options.map(option => {
      option.selected = selected.includes(option.value);
    });
    this.selected = this.options.filter(option => option.selected).map(option => option.value);
  }

  private manageWriteSingle(selectedOptions: string | string[]) {
    const selected = selectedOptions instanceof Array ? selectedOptions[0] : selectedOptions;

    this.options.map(option => {
      option.selected = selected == option.value;
    });
    const optionSelected = this.options.find(option => option.selected);
    this.selected = optionSelected ? optionSelected.value : '';
  }

  private resetOptionsSelected(single = false) {
    this.selected = single ? '' : [];
    this.options.map(option => (option.selected = false));
  }
}
