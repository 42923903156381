import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';

/**
 * Sanitize HTML
 */
@Pipe({
  name: 'safe',
  standalone: true,
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(value: string, type: SecurityContext): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case SecurityContext.HTML:
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case SecurityContext.STYLE:
        return this.sanitizer.bypassSecurityTrustStyle(value);
      case SecurityContext.SCRIPT:
        return this.sanitizer.bypassSecurityTrustScript(value);
      case SecurityContext.URL:
        return this.sanitizer.bypassSecurityTrustUrl(value);
      case SecurityContext.RESOURCE_URL:
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
  }
}
