import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats, MatNativeDateModule } from '@angular/material/core';
import { MatCalendar, MatDatepickerModule } from '@angular/material/datepicker';
import { SafeHtml } from '@angular/platform-browser';
import { IconService } from '@ess-front/shared';
import { ButtonComponent } from '@shared/button/button.component';

@Component({
  selector: 'ess-calendar-custom-header',
  standalone: true,
  imports: [ButtonComponent, MatDatepickerModule, MatNativeDateModule],
  templateUrl: './calendar-custom-header.component.html',
  styleUrls: ['./calendar-custom-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarCustomHeaderComponent<D> {
  chevronLeft: SafeHtml | null;
  chevronRight: SafeHtml | null;

  constructor(
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    private iconService: IconService,
    destroyRef: DestroyRef,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef,
  ) {
    _calendar.stateChanges.pipe(takeUntilDestroyed(destroyRef)).subscribe(() => cdr.markForCheck());

    this.chevronRight = this.iconService.getSVG('chevron_right');
    this.chevronLeft = this.iconService.getSVG('chevron_left');
  }

  get periodLabel() {
    return this._dateAdapter.format(this._calendar.activeDate, this._dateFormats.display.monthYearA11yLabel);
  }

  previousClicked() {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1);
  }

  nextClicked() {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1);
  }
}
