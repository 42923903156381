import { Component, Inject, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '@shared/button/button.component';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormConfig } from '@shared/models/request-trip/form-config.model';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { RequestTripComponent } from './components/request-trip/request-trip.component';
import { RequestTripDomainService } from '@app/domain/request-trip-domain.service';
import { take } from 'rxjs';
import { RequestTrip } from '@shared/models/request-trip/request-trip.model';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'ess-request-trip-modal',
  templateUrl: './request-trip-modal.component.html',
  styleUrls: ['./request-trip-modal.component.scss'],
  standalone: true,
  imports: [MatIconModule, ButtonComponent, MatDialogModule, CdkScrollable, RequestTripComponent, TranslocoDirective],
})
export class RequestTripModalComponent {
  formConfig: FormConfig;
  private readonly requestTripDomainService = inject(RequestTripDomainService);

  constructor(
    @Inject(MAT_DIALOG_DATA) data: { formConfig: FormConfig },
    private dialogRef: MatDialogRef<RequestTripModalComponent>,
  ) {
    this.formConfig = data.formConfig;
  }

  onRequestTripFormSubmit(formValue: RequestTrip) {
    this.requestTripDomainService
      .requestTrip$(formValue)
      .pipe(take(1))
      .subscribe(() => this.dialogRef.close(true));
  }
}
