<ng-container *transloco="let t; prefix: 'requestTrip'">
  <div class="modal-backdrop" [mat-dialog-close]="false"></div>
  <div class="modal-container-surface" cdkScrollable data-cy="request-trip-modal">
    <div class="modal-container request-form" cdkScrollable>
      <button type="button" class="close" mat-dialog-close aria-label="{{ t('close') }}" data-cy="modal-button-close">
        <mat-icon>close</mat-icon>
      </button>
      <div class="modal-title">
        <h2 class="title" data-cy="requestTrip-modal-title">{{ t('requestANewTrip') }}</h2>
        <p class="sans">
          <small data-cy="requestTrip-modal-subtitle">{{ t('pleaseCompleteAllTheInformationBelow') }}</small>
        </p>
      </div>
      <ess-request-trip [formConfig]="formConfig" (formSubmit)="onRequestTripFormSubmit($event)"></ess-request-trip>
    </div>
  </div>
</ng-container>
