<button
  class="chip-button"
  type="button"
  [ngClass]="{selected: selected}"
  [disabled]="disabled"
  [attr.aria-label]="'chip ' + value"
  (click)="toggleChipState()"
  data-cy="chip-button"
>
  <ng-content></ng-content>
  @if (selected) {
    <mat-icon data-cy="chip-icon">clear</mat-icon>
  }
</button>
