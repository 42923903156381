import { Injectable } from '@angular/core';
import { TripService } from '@app/api/trip.service';
import { IOption } from '@app/core/http/http-response.model';
import { RequestTripService } from '@app/core/services/request-trip/request-trip.service';
import { SnackBarService } from '@app/core/services/snack-bar/snack-bar.service';
import { StoreService } from '@app/store/store.service';
import { ChipOption } from '@shared/forms/controls/chip-selector/chip-option.interface';
import { FormConfig } from '@shared/models/request-trip/form-config.model';
import { RequestTrip } from '@shared/models/request-trip/request-trip.model';
import { Observable, catchError, finalize, map, switchMap, tap } from 'rxjs';
import { CategoriesChoicesService } from './../api/categories-choices.service';
import { TranslationDomainService } from './translation-domain.service';
import { marker } from '@jsverse/transloco-keys-manager/marker';

@Injectable({
  providedIn: 'root',
})
export class RequestTripDomainService {
  constructor(
    private categoriesChoicesService: CategoriesChoicesService,
    private store: StoreService,
    private tripService: TripService,
    private requestTripService: RequestTripService,
    private snackBarService: SnackBarService,
    private translateDomain: TranslationDomainService,
  ) {}

  openRequestTripModal$() {
    return this.getFormConfig$().pipe(
      map(formConfig => this.requestTripService.openRequestTripDialog(formConfig)),
      switchMap(ref => ref.afterClosed()),
    );
  }

  getFormConfig$(): Observable<FormConfig> {
    this.store.setIsLoading(true, 'Please wait,');
    return this.categoriesChoicesService.getCategoriesChoices$().pipe(
      finalize(() => this.store.setIsLoading(false)),
      map(categories => ({
        airportAutocompleteEndpoint: AIRPORT_AUTOCOMPLETE_ENDPOINT,
        destinatiosAutocompleteEndpoint: DESTINATIONS_ENDPOINT,
        tripTypeOptions: this.translateDomain.translateObjectArray(TRIP_TYPE_OPTIONS, 'description'),
        passionAndInterestOptions: this.transformIOptionToChipOption(categories),
      })),
    );
  }

  requestTrip$(newTrip: RequestTrip) {
    this.store.setIsLoading(true);
    return this.tripService.postNewTrip$(newTrip).pipe(
      finalize(() => this.store.setIsLoading(false)),
      tap(() => this.snackBarService.open('Your trip has been requested succesfully!')),
      catchError((err: unknown) => {
        this.snackBarService.open('An error ocurred while processing your request, try again after a few seconds.');
        throw err;
      }),
    );
  }

  private transformIOptionToChipOption(options: IOption[]): ChipOption[] {
    return options.map(option => ({
      description: option.label,
      value: option.value,
      selected: false,
    }));
  }
}

const AIRPORT_AUTOCOMPLETE_ENDPOINT =
  'api/autocomplete/airports/airport/?app_label=bookings&model_name=booking&field_name=departure_airport';
const TRIP_TYPE_OPTIONS = [
  {
    description: marker('requestTrip.couple'),
    value: '1',
    selected: false,
  },
  {
    description: marker('requestTrip.familyWithChildren'),
    value: '2',
    selected: false,
  },
  {
    description: marker('requestTrip.friends'),
    value: '3',
    selected: false,
  },
  {
    description: marker('requestTrip.solo'),
    value: '5',
    selected: false,
  },
  {
    description: marker('requestTrip.business'),
    value: '4',
    selected: false,
  },
];
const DESTINATIONS_ENDPOINT = 'api/destinations/?';
